import React from "react";
import Support from "../../components/Support";
import { useTranslation } from "react-i18next";
import CarouselCustom from "../../components/CarouselCustom";
const SupportScreen = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
		// eslint-disable-next-line
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<Support />
		</>
	);
};

export default SupportScreen;
