import React, { useState } from "react";
import CarouselCustom from "../../components/CarouselCustom";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../../data";
import { Container, Modal } from "react-bootstrap";
import Login from "../Login";
import VideoModal from "../../components/VideoModal";
const Expired = (props) => {
	const { t, i18n } = useTranslation();
	const [showVideoModal, setShowVideoModal] = useState(false);
	const selected_lang = i18n.language;
	const config = useSelector((state) => state.app.config);
	const handleToggleModal = () => {
		setShowVideoModal(!showVideoModal);
	};
	return (
		<Container>
			<h1 className="text-center border-bottom mt-5" style={{ color: "black", textTransform: "capitalize" }}>
			404 Not Found
			</h1>
		</Container>
	);
};

export default IsLoadingHOC(Expired, "");
