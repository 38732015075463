import React from "react";
import { Modal } from "react-bootstrap";

const ImageModal = (props) => {
	const handleToggle = () => {
		props.handleClose();
	};
	return (
		<div>
			<Modal size="lg" className="img-modal" centered show={props.isOpen} onHide={handleToggle} backdrop="static" keyboard={false}>
				<Modal.Header closeButton></Modal.Header>
				<Modal.Body className="d-flex align-items-center justify-content-center">
					<img className="w-100" src={props.src} alt={props.alt} />
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default ImageModal;
