export const portalPoints = {
	statusCode: 200,
	headers: {
		"Content-type": "application/json",
	},
	body: {
		portalPoints: [
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-50d4675ec91014d74907ac0b62ce526a",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Shrek: Silent Game Challenge",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-21c87fc80c7c2de1e98fcc8cfd490be4",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "The Mauritanian: Trailer",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-1941ac23f317226aaef853e0f98c6e12",
				channel: "questions",
				points: 5,
				times: 1,
				actionType: "survey",
				title: "Take our Shrek poll",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-7b6b6822fc5ad7109fc9f50eb93cbeb6",
				channel: "questions",
				points: 25,
				times: 1,
				actionType: "survey",
				title: "Liam Neeson Quiz",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-2afa9b6277aadffabc3271a395d3e9de",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "The Marksman: Extended Preview",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-4cc87a300609dafe98f3e4ca797965c1",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Shrek: Who's The Greatest Hero?",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-58d4d5c9ef89ad05ee7fd2b124e46281",
				channel: "questions",
				points: 25,
				times: 1,
				actionType: "survey",
				title: "Shrek’s Ultimate Fan Quiz\n",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-3b068181d9f8b8ea16de2ea5fb41239b",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "How To Train Your Dragon: The Hidden World: Trailer",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-64459dc10ac31a7a7fba9fc819161ee0",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Parasite: Trailer",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-20bc217faddfb1aace9f84538bc6209a",
				channel: "questions",
				points: 25,
				times: 1,
				actionType: "survey",
				title: "Universal's Award-Winning Films Quiz",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-8f70c0a7281b2d598a72d8b0953a618f",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "1917: Behind-The-Scenes",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-87f67f44a1f7a2b6effa977fcc8fccc",
				channel: "questions",
				points: 25,
				times: 1,
				actionType: "survey",
				title: "DreamWorks Animation Award-Winning Films Quiz",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-a73517ffb7dfa407be075a92f51a912d",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Greenbook: Behind-The-Scenes",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-d73cb9011f117052754c2a84d4a13e13",
				channel: "questions",
				points: 25,
				times: 1,
				actionType: "survey",
				title: "Awards Season Survey",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-5f624bb676dbf3aa19ba58b464d28173",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Half Brother: Extended Preview",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-a5d62429494ed0286979364b1a4af337",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "The Croods: A New Age - Behind The Scenes",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-54872a030addef34087d7574929f64d",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "News of The World: Behind-The-Scenes",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-c4550a6c5683f45d345d78d955b133d2",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Greenland: Trailer",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-989981be0e38bfb091293ab0bb60aa8",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "The Croods: A New Age - Extended Preview",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-dade7e157b7da42eb4f6c14ccf7e917f",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Boss Baby: Film Clip",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-6ddd7130e0fef97c1956ff41c6f05506",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "Trolls World Tour: Country & Funk Trolls Bonus Clip",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-8d6dd1cb503f15e5283e6736528408a1",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "How To Train Your Dragon: Extended preview",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-570fcca99d88877a158dd18017e95f4c",
				channel: "microsite",
				points: 5,
				times: 1,
				actionType: "checkin",
				title: "We could all use some laughs! ",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-cb5f0279e5993a703fd0482fdb51a397",
				channel: "microsite",
				points: 20,
				times: 1,
				actionType: "checkin",
				title: "Halloween Memes",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-61354c157613653fce1a76b8920a6dd2",
				channel: "facebook",
				points: 15,
				times: 1,
				actionType: "pagelike",
				title: "Digital Movie Deals",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-25224f272981ed9f7caa65d936a67195",
				channel: "facebook",
				points: 15,
				times: 1,
				actionType: "pagelike",
				title: "Movies Anywhere",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-8dedc7d2bf41fbaadd98f14af59c2251",
				channel: "facebook",
				points: 15,
				times: 1,
				actionType: "pagelike",
				title: "Gruv",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-facebook-730c4d1a43c6452251a80967d29c3d05",
				channel: "facebook",
				points: 15,
				times: 1,
				actionType: "pagelike",
				title: "Universal Studios Entertainment",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-questions-dd0f29b45402d92d24acf9480608adb9",
				channel: "questions",
				points: 25,
				times: 1,
				actionType: "survey",
				title: "Tell Us About Yourself! ",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-video-8a1290808999e75cda031bce22ab11d4",
				channel: "video",
				points: 15,
				times: 1,
				actionType: "view",
				title: "The Turning: Trailer ",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-999488dec0a57297863e6378ca175d9f",
				channel: "microsite",
				points: 50,
				times: 1,
				actionType: "contest",
				title: "Start Earning Points for Digital Purchases!",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-microsite-8ec8927236bd6f079aee6db369f103f9",
				channel: "microsite",
				points: 50,
				times: 1,
				actionType: "contest",
				title: "Share your movie collection",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
			{
				compositeKey: "8be1c7b396cf9d694babd31c3202f0bc-universal-kiosk-4cbb5565ac3966fb6156ac6117ce28b5",
				channel: "kiosk",
				points: 100,
				times: 1,
				actionType: "checkin",
				title: "Refer a Friend",
				cardHeaderImage: "https://via.placeholder.com/350",
			},
		],
	},
};

export const menu = {
	statusCode: 200,
	headers: {
		"Content-type": "application/json",
	},
	body: {
		menuStructure: [
			{
				id: "343",
				description: "Menu 1",
				hasSubmenu: true,
				submenu: [
					{
						id: "343",
						description: "Sub Menu 1",
						hasSubmenu: false,
						href: "/validate-code",
					},
					{
						id: "364",
						description: "Sub Menu 2",
						hasSubmenu: false,
						href: "/manage-account/movies-anywhere",
					},
					{
						id: "324",
						description: "Sub Menu 3",
						hasSubmenu: false,
						href: "/upload-receipt",
					},
					{
						id: "334",
						description: "Sub Menu 4",
						hasSubmenu: false,
						href: "/eligible-movies",
					},
				],
			},
			{
				id: "314",
				description: "Menu 2",
				hasSubmenu: false,
				href: "/rewards",
			},
			{
				id: "214",
				description: "Menu 3",
				hasSubmenu: false,
				href: "/contest",
			},
			{
				id: "564",
				description: "Menu 4",
				hasSubmenu: false,
				href: "/earnpoints",
			},
			{
				id: "314",
				description: "Menu 5",
				hasSubmenu: true,
				submenu: [
					{
						id: "343",
						description: "Sub Menu 1",
						hasSubmenu: false,
						href: "/validate-code",
					},
					{
						id: "364",
						description: "Sub Menu 2",
						hasSubmenu: false,
						href: "/extras",
					},
				],
			},
			{
				id: "3414",
				description: "Menu 6",
				image: "https://platform3-portalv3-production.s3-us-west-2.amazonaws.com/uniallaccess/assets/common/perks/perk-1-icon.png",
				hasSubmenu: true,
				submenu: [
					{
						id: "2",
						description: "Sub Menu 1",
						hasSubmenu: false,
						href: "/validate-code",
					},
					{
						id: "3",
						description: "Sub Menu 2",
						hasSubmenu: false,
						href: "/manage-account/point-history",
					},
					{
						id: "4",
						description: "Sub Menu 3",
						hasSubmenu: false,
						href: "/manage-account/my-rewards",
					},
					{
						id: "5",
						description: "Sub Menu 4",
						hasSubmenu: false,
						href: "/manage-account/settings",
					},
					{
						id: "6",
						description: "Sub Menu 5",
						hasSubmenu: false,
						href: "/manage-account/movies-anywhere",
					},
					{
						id: "7",
						description: "Logout",
						hasSubmenu: false,
						href: "/logout",
					},
				],
			},
		],
	},
};

export const user = {
	statusCode: 200,
	headers: {
		"Content-type": "application/json",
	},
	body: {
		compositeKey: "9689a439-6617-4c4f-98cd-ebd3fdb64731",
		clientid: "8be1c7b396cf9d694babd31c3202f0bc",
		userid: "259b3f861a2c6917bb2c45dbe6a71742",
		firstName: "Test",
		lastName: "User",
		email: "earther2k@gmail.com",
		emailHash: "24afcbd33f514c7e02d23ef9232db0c",
		birth: 466239600000,
		gender: "male",
		phone: {
			homePhone: "",
			cellPhone: "",
		},
		city: "",
		postal: "94555",
		receiveEmail: true,
		receiveSms: false,
		suspended: false,
		addressDetails: {
			address: "",
			state: "",
			suite: "",
			country: "",
			shippingInstructions: "",
		},
		additionalInfo: {
			universalFirstLoginPopUp: "",
			tier: "0",
			universalPopUpCta: "",
			username: "earther2k",
		},
		created: 1615365850356,
		updated: 1615365850532,
	},
};

export const pointHistory = {
	statusCode: 200,
	headers: {
		"Content-type": "application/json",
	},
	body: {
		pointHistory: [
			{
				ledger: "credit",
				points: 15,
				description: "You earned points for watching Parasite: Trailer",
				date: "04/23/2021 02:14",
				isAdjustment: false,
			},
			{
				ledger: "credit",
				points: 10,
				description: "Thanks! You did something to earn points.",
				date: "04/22/2021 07:42",
				isAdjustment: false,
			},
			{
				ledger: "debit",
				points: 50,
				description: "Thanks! You did something to earn points.",
				date: "04/22/2021 07:42",
				isAdjustment: false,
			},
			{
				ledger: "debit",
				points: 2,
				description: "Thanks! You did something to earn points.",
				date: "04/16/2021 00:25",
				isAdjustment: false,
			},
			{
				ledger: "debit",
				points: 5,
				description: "Thanks! You did something to earn points.",
				date: "04/09/2021 00:07",
				isAdjustment: false,
			},
			{
				ledger: "credit",
				points: 15,
				description: "You earned points for watching Greenbook: Behind-The-Scenes",
				date: "04/09/2021 00:07",
				isAdjustment: false,
			},
			{
				ledger: "credit",
				points: 5,
				description: "Thanks for participating in our Tom Hanks Poll!",
				date: "03/31/2021 00:43",
				isAdjustment: false,
			},
			{
				ledger: "debit",
				points: 5,
				description: "Thanks! You did something to earn points.",
				date: "03/19/2021 09:09",
				isAdjustment: false,
			},
			{
				ledger: "credit",
				points: 15,
				description: "You earned points for watching The Croods: A New Age - Behind The Scenes",
				date: "03/10/2021 00:50",
				isAdjustment: false,
			},
			{
				ledger: "credit",
				points: 15,
				description: "You earned points for watching News of The World: Behind-The-Scenes",
				date: "03/10/2021 00:48",
				isAdjustment: false,
			},
		],
		total: 11,
	},
};

export const footerMenu = [
	{
		title: "About Us",
		id: 1,
		route: "https://pgpro.com/en-us/about-us",
	},
	{
		title: "Contact Us",
		id: 2,
		route: "route",
	},
	{
		title: "Privacy",
		id: 3,
		route: "route",
	},
	{
		title: "CA Privacy",
		id: 4,
		route: "route",
	},
	{
		title: "Terms & Conditions",
		id: 5,
		route: "route",
	},
	{
		title: "Do Not Sell My Personal Information",
		id: 6,
		route: "route",
	},
];

export const footerSocial = [
	{
		route: "https://www.facebook.com/UniversalPicturesAllAccess",
		name: "facebook",
		id: 1,
	},
	{
		route: "https://twitter.com/UniAllAccess",
		name: "facebook",
		id: 1,
	},
	{
		route: "https://www.instagram.com/uniallaccess/",
		name: "facebook",
		id: 1,
	},
];

export const contests = {
	httpCode: 200,
	message: "Record retrieved successfully",
	data: {
		content: [
			{
				maxAttempt: 1,
				minimumActivityDuration: 30,
				pointsEnabled: true,
				channel: "spin",
				title: "The Addams Family 2 Spin & Win",
				description: `Celebrate the release of The Addams Family 2 on Blu-ray & DVD. Everyone's favorite spooky family is back! Morticia and Gomez decide to cram Wednesday, Pugsley, Uncle Fester and the crew into their haunted camper and hit the road for a miserable family vacation. What could possibly go wrong?
Spin for a chance to win The Addams Family 2 on Blu-ray, Wednesday's Library - a book signed by the cast, the family as Funko Pop collectible figurines and more!
Come back and spin daily. Each spin costs 50 points. Click here to read the rules.`,
				cardHeaderImage: "/public/webstatic/images/AddamsFamily2_SpinWin_tout.jpg",
				data: [
					{ text: "Addams Family enter 2 Blu-ray", id: 1, fillStyle: "#EE4040" },
					{ text: "10 Bonus points", id: 2, fillStyle: "#F0CF50" },
					{ text: "Addams Family enter \n2-Movie Collection", id: 3, fillStyle: "#815CD1" },
					{ text: '"Wednesday\'s Library" \nenter signed book', id: 4, fillStyle: "#3DA5E0" },
					{ text: "50 Bonus points", id: 5, fillStyle: "#34A24F" },
					{ text: "Free Spin", id: 6, fillStyle: "#F9AA1F" },
					{ text: '"Art of the Animated\nenter Movie" Book', id: 7, fillStyle: "#EC3F3F" },
					{ text: "5 Bonus points", id: 8, fillStyle: "#FF9000" },
					{ text: "Addams Family enter\nFunko Pop Set", id: 9, fillStyle: "#FF9000" },
					{ text: "Sorry, Play Again", id: 10, fillStyle: "#EC3F3F" },
				],
				actionType: "spin-win",
				points: 50,
				times: 1,
				createdAt: "2021-12-27T09:35:21.418Z",
				updatedAt: "2021-12-27T09:35:21.418Z",
				id: "61c988d9559b980008a515aa",
			},
			{
				maxAttempt: 1,
				minimumActivityDuration: 30,
				pointsEnabled: true,
				channel: "spin",
				title: "No Time To Die Spin & Win",
				description: `We’re celebrating the release of No Time To Die on 4K UHD, Blu-ray & DVD. With more than an hour of bonus content exploring the action, spectacle and stunts, fans can go behind-the-scenes of the film and deeper into the “thrilling and emotional” (Deadline) legacy of Daniel Craig’s 007.
Spin for a chance to win The Daniel Craig Collection, James Bond Movie Collection or a No Time To Die Limited Edition 4K UHD Gift Set. The gift set features an official Aston Martin die cast model car with a numbered Letter of Authenticity.
Come back and spin daily. Each spin costs 50 points. Click here to read the rules.`,
				cardHeaderImage: "/public/webstatic/images/NoTimeToDie_SpinWin_tout.jpg",
				data: [
					{ text: "No Time To Die 4K", id: 1, fillStyle: "#EE4040" },
					{ text: "5 Bonus points", id: 2, fillStyle: "#F0CF50" },
					{ text: "No Time To Die Digital", id: 3, fillStyle: "#815CD1" },
					{ text: "James Bond 24-Movie\nenter Collection Blu-ray", id: 4, fillStyle: "#3DA5E0" },
					{ text: "50 Bonus points", id: 5, fillStyle: "#34A24F" },
					{ text: "No Time To Die Blu-ray", id: 6, fillStyle: "#F9AA1F" },
					{ text: "10 Bonus points", id: 7, fillStyle: "#EC3F3F" },
					{ text: "Daniel Craig 4-Movie\nenter Collection 4K", id: 8, fillStyle: "#FF9000" },
					{ text: "Free Spin", id: 9, fillStyle: "#FF9000" },
					{ text: "No Time To Die \nenter Gift set 4K", id: 10, fillStyle: "#F0CF50" },
					{ text: "Sorry, Play Again", id: 11, fillStyle: "#EC3F3F" },
				],
				actionType: "spin-win",
				points: 50,
				times: 1,
				createdAt: "2021-12-27T09:35:21.418Z",
				updatedAt: "2021-12-27T09:35:21.418Z",
				id: "61c988d9559b980008a51567",
			},
		],
		metaData: { limit: 4, currentPageNo: 0, totalRecs: 11, nextPageNo: 1 },
	},
};

export const footerCopyRight = {
	copyRightText: "Copy Right Text Goes Here. All Rights Reserved",
	poweredText: "Powered By Text Or Logo Here",
};

export const logo = "/public/images/logo.png";
export const uploadIcon = "/public/images/upload_icon.png";

export const carouselData_en = [
	{
		img: "/public/images/desktop-banner_en.png",
		mobile_img: "/public/images/mobile-banner_en.png",
		title: "Spend $100* on P&G products and get $25 in a Digital Costco Shop Card OR Spend $200* on P&G products and get $50 in Digital Costco Shop Cards.  Offer Valid 02/26/24 – 04/07/24 on purchases made at Costco Canada only.  See full terms and conditions.",
	},
];
export const carouselData_fr = [
	{
		img: "/public/images/desktop-banner_fr.png",
		mobile_img: "/public/images/mobile-banner_fr.png",
		title: "Dépensez 100 $* sur des produits P&G et obtenez 25 $ sous forme d’une carte Achat numérique de Costco OU dépensez 200 $* sur des produits P&G et obtenez 50 $ sous forme de cartes Achat numériques de Costco.  Offre valide du 6 février au 7 avril 2024 sur des achats effectués chez Costco Canada seulement.   Voir les conditions générales complètes.",
	},
];
export const carouselData_en_exp = [
	{
		img: "/public/images/desktop-banner_en.png",
		mobile_img: "/public/images/mobile-banner_en.png",
		title: "Spend $100* on P&G products and get $25 in a Digital Costco Shop Card OR Spend $200* on P&G products and get $50 in Digital Costco Shop Cards.  Offer Valid 02/26/24 – 04/07/24 on purchases made at Costco Canada only.  See full terms and conditions.",
	},
];
export const carouselData_fr_exp = [
	{
		img: "/public/images/desktop-banner_fr.png",
		mobile_img: "/public/images/mobile-banner_fr.png",
		title: "Dépensez 100 $* sur des produits P&G et obtenez 25 $ sous forme d’une carte Achat numérique de Costco OU dépensez 200 $* sur des produits P&G et obtenez 50 $ sous forme de cartes Achat numériques de Costco.  Offre valide du 6 février au 7 avril 2024 sur des achats effectués chez Costco Canada seulement.   Voir les conditions générales complètes.",
	},
];
export const commonImgData_en_exp = {
	img1: "/public/images/steps_desktop_en.png",
	mobile_img1: "/public/images/steps_mobile_en.png",
	title1: `How to participate:
	Step 1: Spend on P&G products† at Costco in-warehouse and/or online.
	Step 2: Submit receipts. Proof of purchase by May 5, 2024.
	Step 3: Get a Digital Costco Shop Card. Maximum $50 per Costco Membership number.`,
	img2: "/public/images/2_desktop_en.png",
	mobile_img2: "/public/images/2_mobile_en.png",
	title2:
		"†Participating Brands - Tide, Bounce, Downy, Gain, Ivory Snow, Dawn, Cascade, Mr. Clean, Gillette, Gillette Venus, Crest, Oral-B (excludes Oral-B iO7c, iO6, iO5 Superior Clean, Oral-B PRO5000, Oral-B Smart Clean 360, Oral-B 2-in-1 Professional Dental Care Kit.), Glide, Always, Tampax, Olay, Herbal Essences, Pantene, Head & Shoulders, Old Spice, Metamucil, Align, Charmin, Vicks (excluded in Quebec), Downy Unstopables, Swiffer, Febreze, Bounty, Clearblue (excludes Fertility Monitor).",
	img3: "/public/images/3_desktop_en.png",
	mobile_img3: "/public/images/3_mobile_en.png",
	title3: "TBC",
	img4: "/public/images/video_icon.png",
	title4: "Questions? Watch the video.",
	img5: "/public/images/download.png",
	title5: "Download a mail-in form.",
	mail_form: "/public/video/Mail-In Form EN.pdf",
	question_video: "/public/video/Spend Get 24 Instructional Video_EN.mp4",
	img6: "/public/images/desktop_brands_en.png",
	title6: "Excludes: Braun, Clearblue Fertility Monitor, SKII Facial Treatment Essence, Oral-B iO7c, iO6, iO5 Superior Clean,  Oral-B PRO5000, Oral-B Smart Clean 360, Oral-B 2-in-1 Professional Dental Care Kit, Vicks (Quebec only).",
	mobile_img4: "/public/images/mobile_brands_en.png",
	img7: "/public/images/membership-card-en.png",
	title7: "Gold Star, Executive or Business Membership",
	img8: "/public/images/digital-card-en.png",
	title8: "Digital Membership Card",
	img9: "/public/images/cobrand-card-en.png",
	title9: "The CIBC Costco Mastercard, exclusively for Costco members",
	img10: "/public/images/email_en.png",
	title10: "order receipt emails",
	img11: "/public/images/cart_view_en.png",
	title11: "order receipt emails",
	img12: "/public/images/upload_re_en.png",
	title12: "upload Receipt",
	img13: "/public/images/store-en.png",
	title13: "CLICK HERE SUBMIT YOUR IN-WAREHOUSE RECEIPT",
	img14: "/public/images/computer-en.png",
	title14: "CLICK HERE SUBMIT YOUR COSTCO.CA OR COSTCOBUSINESSCENTRE.CA RECEIPT",
	img15: "/public/images/express-delivery-en.png",
	title15: "CLICK HERE TO SUBMIT YOUR SAME-DAY DELIVERY RECEIPT",
	img16: "/public/images/program_ex_en.png",
	mobile_img5: "/public/images/program_ex_mobile_en.png",
	title16: `How to participate:
	Step 1: Spend on P&G products† at Costco in-warehouse and/or online.
	Step 2: Submit receipts. Proof of purchase by May 5, 2024.
	Step 3: Get a Digital Costco Shop Card. Maximum $50 per Costco Membership number.`,
	img17: "/public/images/SubmissionExpired_en.png",
	mobile_img6: "/public/images/SubmissionExpired_mobile_en.png",
	title17: `How to participate:
	Step 1: Spend on P&G products† at Costco in-warehouse and/or online.
	Step 2: Submit receipts. Proof of purchase by May 5, 2024.
	Step 3: Get a Digital Costco Shop Card. Maximum $50 per Costco Membership number.`,
	products: [
		{
			category: "LAUNDRY NEEDS",
			product_logo: [
				{ url: "/public/images/products/en/tide.png", title: "tide logo", description: "" },
				{ url: "/public/images/products/en/gain.png", title: "gain logo", description: "" },
				{ url: "/public/images/products/en/downy.png", title: "downy logo", description: "" },
				{ url: "/public/images/products/en/bounce.png", title: "bounce logo", description: "" },
				{ url: "/public/images/products/en/ivory.png", title: "ivory logo", description: "" },
			],
		},
		{
			category: "HOUSEHOLD NEEDS",
			product_logo: [
				{ url: "/public/images/products/en/dawn.png", title: "dawn logo", description: "" },
				{ url: "/public/images/products/en/cascade.png", title: "cascade logo", description: "" },
				{ url: "/public/images/products/en/swiffer.png", title: "swiffer logo", description: "" },
				{ url: "/public/images/products/en/febreze.png", title: "febreze logo", description: "" },
				{ url: "/public/images/products/en/mrclean.png", title: "mrclean logo", description: "" },
				{ url: "/public/images/products/en/bounty.png", title: "bounty logo", description: "" },
				{ url: "/public/images/products/en/charmin.png", title: "charmin logo", description: "" },
			],
		},
		{
			category: "HEALTH & BEAUTY",
			product_logo: [
				{ url: "/public/images/products/en/gillette.png", title: "gillette logo", description: "" },
				{ url: "/public/images/products/en/venus.png", title: "venus logo", description: "" },
				{ url: "/public/images/products/en/olay.png", title: "olay logo", description: "" },
				{ url: "/public/images/products/en/pantene.png", title: "pantene logo", description: "" },
				{ url: "/public/images/products/en/head_shoulder.png", title: "head_shoulder logo", description: "" },
				{ url: "/public/images/products/en/crest.png", title: "crest logo", description: "" },
				{
					url: "/public/images/products/en/oral-b.png",
					title: "oral-b logo",
					description: "Excludes: Oral-B i07c, i06, iO Superior Clean, PRO5000, Smart Clean 360, Ora-B 2in-1 Professional Dental Care Kit",
				},
				{ url: "/public/images/products/en/glide.png", title: "glide logo", description: "" },
				{ url: "/public/images/products/en/always.png", title: "always logo", description: "" },
				{ url: "/public/images/products/en/tampax.png", title: "tampax logo", description: "" },
				{ url: "/public/images/products/en/herbal-essences.png", title: "herbal-essences logo", description: "" },
				{ url: "/public/images/products/en/old-spice.png", title: "old-spice logo", description: "" },
				{ url: "/public/images/products/en/meta-mucil.png", title: "meta-mucil logo", description: "" },
				{ url: "/public/images/products/en/align.png", title: "align logo", description: "" },
				{ url: "/public/images/products/en/vicks.png", title: "vicks logo", description: "(Excluded in Quebec)" },
				{ url: "/public/images/products/en/clearblue.png", title: "clearblue logo", description: "Excludes Fertility Monitor", class: "mt-2" },
			],
		},
	],
};
export const commonImgData_fr_exp = {
	img1: "/public/images/steps_desktop_fr.png",
	mobile_img1: "/public/images/steps_mobile_fr.png",
	title1: `Comment participer :
	Étape 1 : Dépensez sur des produits P&G† chez Costco en entrepôt ou en ligne.
	Étape 2 : Soumettez vos reçus. Une preuve d’achat d’ici le 5 mai 2024.
	Étape 3 : Obtenez une carte Achat numérique de Costco. Un maximum de 50 $ par numéro de membre de Costco.`,
	img2: "/public/images/2_desktop_fr.png",
	mobile_img2: "/public/images/2_mobile_fr.png",
	title2:
		"† Marques participantes - Tide, Bounce, Downy, Gain, Ivory Neige, Dawn, Cascade, M. Net, Gillette, Gillette Venus, Crest, Oral-B (à l’exception des brosses à dents Oral-B iO7c, iO6, iO5 Nettoyage supérieur, Oral-B PRO5000, Oral-B Propreté intelligente 360 et la trousse professionnelle de soins dentaires 2 en 1), Glide, Always, Tampax, Olay, Herbal Essences, Pantene, Head & Shoulders, Old Spice, Metamucil, Align, Charmin, Vicks (exclu au Québec), Downy Unstopables, Swiffer, Febreze, Bounty, Clearblue (le moniteur de fertilité est exclu de l’offre).",
	img3: "/public/images/3_desktop_fr.png",
	mobile_img3: "/public/images/3_mobile_fr.png",
	title3: "TBC",
	img4: "/public/images/video_icon.png",
	title4: "Questions? Visionnez la vidéo.",
	img5: "/public/images/download.png",
	title5: "Téléchargez le formulaire papier.",
	mail_form: "/public/video/Mail-In Form FR.pdf",
	question_video: "/public/video/24COSSG02 Spend Get 24 Instructional Video FR.mp4",
	img6: "/public/images/desktop_brands_fr.png",
	title6: "Produits exclus : Braun, moniteur de fertilité Clearblue, essence de soin du visage SKII, les brosses à dents Oral-B iO7c, iO6, iO5 Nettoyage supérieur,  Oral-B PRO5000, Oral-B Propreté intelligente 360, la trousse professionnelle de soins dentaires 2 en 1 et Vicks (au Québec seulement).",
	mobile_img4: "/public/images/mobile_brands_fr.png",
	img7: "/public/images/membership-card-fr.png",
	title7: "Membre Privilège,Exécutif ou Entreprise",
	img8: "/public/images/digital-card-fr.png",
	title8: "Carte de membre numérique",
	img9: "/public/images/cobrand-card-fr.png",
	title9: "La carte CIBC Costco MasterCard, exclusive aux membres de Costco",
	img10: "/public/images/email_fr.png",
	title10: "VOTRE COURRIEL DE CONFIRMATION D’EXPÉDITION COSTCO.CA RESSEMBLERA À CECI",
	img11: "/public/images/cart_view_fr.png",
	title11: "order receipt emails",
	img12: "/public/images/upload_re_fr.png",
	title12: "upload Receipt",
	img13: "/public/images/store-fr.png",
	title13: "CLIQUEZ ICI POUR SOUMETTRE VOTRE REÇU OBTENU DANS L’UN DE NOS ENTREPÔTS",
	img14: "/public/images/computer-fr.png",
	title14: "CLIQUEZ ICI POUR SOUMETTRE VOTRE REÇU DE COSTCO.CA OU DE COSTCOPOURLENTREPRISE.CA",
	img15: "/public/images/express-delivery-fr.png",
	title15: "CLIQUEZ ICI POUR SOUMETTRE VOTRE REÇU DE LIVRAISON POUR LE MÊME JOUR",
	img16: "/public/images/program_ex_fr.png",
	mobile_img5: "/public/images/program_ex_mobile_fr.png",
	title16: `Comment participer :
	Étape 1 : Dépensez sur des produits P&G† chez Costco en entrepôt ou en ligne.
	Étape 2 : Soumettez vos reçus. Une preuve d’achat d’ici le 5 mai 2024.
	Étape 3 : Obtenez une carte Achat numérique de Costco. Un maximum de 50 $ par numéro de membre de Costco.`,
	img17: "/public/images/SubmissionExpired_fr.png",
	mobile_img6: "/public/images/SubmissionExpired_mobile_fr.png",
	title17: `Comment participer :
	Étape 1 : Dépensez sur des produits P&G† chez Costco en entrepôt ou en ligne.
	Étape 2 : Soumettez vos reçus. Une preuve d’achat d’ici le 5 mai 2024.
	Étape 3 : Obtenez une carte Achat numérique de Costco. Un maximum de 50 $ par numéro de membre de Costco.`,
	products: [
		{
			category: "LESSIVE",
			product_logo: [
				{ url: "/public/images/products/fr/tide.png", title: "tide logo", description: "" },
				{ url: "/public/images/products/fr/gain.png", title: "gain logo", description: "" },
				{ url: "/public/images/products/fr/downy.png", title: "downy logo", description: "" },
				{ url: "/public/images/products/fr/bounce.png", title: "bounce logo", description: "" },
				{ url: "/public/images/products/fr/ivory.png", title: "ivory logo", description: "" },
			],
		},
		{
			category: "ENTRETIEN MÉNAGER",
			product_logo: [
				{ url: "/public/images/products/fr/dawn.png", title: "dawn logo", description: "" },
				{ url: "/public/images/products/fr/cascade.png", title: "cascade logo", description: "" },
				{ url: "/public/images/products/fr/swiffer.png", title: "swiffer logo", description: "" },
				{ url: "/public/images/products/fr/febreze.png", title: "febreze logo", description: "" },
				{ url: "/public/images/products/fr/mr-net.png", title: "mr-net logo", description: "" },
				{ url: "/public/images/products/fr/bounty.png", title: "bounty logo", description: "" },
				{ url: "/public/images/products/fr/charmin.png", title: "charmin logo", description: "" },
			],
		},
		{
			category: "BEAUTÉ ET SANTÉ",
			product_logo: [
				{ url: "/public/images/products/fr/gillette.png", title: "gillette logo", description: "" },
				{ url: "/public/images/products/fr/venus.png", title: "venus logo", description: "" },
				{ url: "/public/images/products/fr/olay.png", title: "olay logo", description: "" },
				{ url: "/public/images/products/fr/pantene.png", title: "pantene logo", description: "" },
				{ url: "/public/images/products/fr/head-shoulder.png", title: "head-shoulder logo", description: "" },
				{ url: "/public/images/products/fr/crest.png", title: "crest logo", description: "" },
				{
					url: "/public/images/products/fr/oral-b.png",
					title: "oral-b logo",
					description: "Produits exclus : les brosses à dents Ora-B i07c, i06, iO Nettoyage supérieur, PRO5000, Propreté intelligente 360, la trousse professionnelle de soins dentaires 2 en 1",
				},
				{ url: "/public/images/products/fr/glide.png", title: "glide logo", description: "" },
				{ url: "/public/images/products/fr/always.png", title: "always logo", description: "" },
				{ url: "/public/images/products/fr/tampax.png", title: "tampax logo", description: "" },
				{ url: "/public/images/products/fr/herbal-essences.png", title: "herbal-essences logo", description: "" },
				{ url: "/public/images/products/fr/old-spice.png", title: "old-spice logo", description: "" },
				{ url: "/public/images/products/fr/meta-mucil.png", title: "meta-mucil logo", description: "" },
				{ url: "/public/images/products/fr/align.png", title: "align logo", description: "" },
				// { url: "/public/images/products/en/vicks.png", title: "vicks logo", description: "" },
				{ url: "/public/images/products/fr/clearblue.png", title: "clearblue logo", description: "Le moniteur de fertilité est exclu de l'offre", class: "mt-5" },
			],
		},
	],
};
