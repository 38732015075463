import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import IsLoadingHOC from "./IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadIcon } from "../data";
const FileDropZone = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;

	const [selectedFile, setSelectedFile] = useState([]);
	const maxUploadSize = 5;
	const onDrop = useCallback((acceptedFiles, fileRejections) => {
		if (acceptedFiles.length > 0 && fileRejections.length === 0) {
			let promiceList = [];
			let pdfInd = -1,
				tfilesize = 0;
			for (let i in acceptedFiles) {
				let file = acceptedFiles[i];
				if (file.type === "application/pdf") {
					pdfInd = i;
				} else {
					tfilesize += file.size;
					promiceList.push(fileToImageURL(file));
				}
			}
			if (pdfInd >= 0) {
				setSelectedFile([acceptedFiles[pdfInd]]);
				props.parentCallBack([acceptedFiles[pdfInd]]);
			} else {
				if (tfilesize >= maxUploadSize * 1000 * 1000) {
					toast.error(t("UPLOAD.UPLOAD_BOX.ERROR.SIZE_EXCEED"));
				} else {
					if (promiceList.length > 0) {
						Promise.all(promiceList)
							.then((values) => {
								setSelectedFile(values);
								if (values.length === 1) {
									props.parentCallBack([acceptedFiles[0]]);
								} else {
									props.parentCallBack(values);
								}
							})
							.catch((err) => {
								console.log("error in convertion of image to image link");
							});
					}
				}
			}
		}
		if (fileRejections.length > 0) {
			if (fileRejections.length > 3) {
				toast.error(t("UPLOAD.UPLOAD_BOX.ERROR.MAX_NUMBER_FILE"));
			} else {
				let maxFileErrCnt = 0;
				fileRejections.forEach((file) => {
					file.errors.forEach((err) => {
						if (err.code === "file-too-large" && maxFileErrCnt < 1) {
							maxFileErrCnt++;
							toast.error(t("UPLOAD.UPLOAD_BOX.ERROR.SIZE_EXCEED"));
						}
					});
				});
			}
		}
	}, []);
	React.useEffect(() => {
		if (props.selectedFile.length === 0) {
			setSelectedFile([]);
		}
	}, [props.selectedFile]);
	const { getRootProps, getInputProps, open } = useDropzone({ noClick: true, disabled: !props.enableUploading, onDrop, multiple: true, maxFiles: 3, maxSize: maxUploadSize * 1000 * 1000, accept: "image/jpeg,image/png,image/jpg,application/pdf" });
	const fileToImageURL = (file) => {
		return new Promise(async (resolve, reject) => {
			try {
				const image = new Image(file);
				image.fileType = file.type;
				image.onload = () => {
					resolve(image);
				};
				image.onerror = () => {
					reject(new Error(t("UPLOAD.UPLOAD_BOX.ERROR.FILE_CONVERSION")));
				};
				image.src = URL.createObjectURL(file);
			} catch (err) {
				reject(err);
			}
		});
	};
	return (
		<Container>
			<Row className="my-3">
				<div className="col-12">
					<button align="center" className="select-lable w-100" onClick={open}>
						{t("UPLOAD.SELECT_BTN")}
					</button>
				</div>
			</Row>
			<Row className="justify-content-md-center">
				<div className="col-12">
					<div md={12} {...getRootProps()} className="text-center dropzone-div">
						<input {...getInputProps()} accept="" aria-label="UploadReceipt" />
						{selectedFile.length > 0 ? (
							selectedFile.map((v, i) => {
								return (
									<div className="placeholder text-center" key={`key-${i}`}>
										{v.type === "application/pdf" ? v.name : <img src={v.src} width="90%" style={{ maxHeight: "100%" }} alt="receipt" />}
									</div>
								);
							})
						) : (
							<p className="f18">
								{!props.enableUploading ? (
									<>{t("UPLOAD.ERROR.LIMIT_REACHED")}</>
								) : (
									<>
										<p className="text-success">{t("UPLOAD.UPLOAD_BOX.TEXT1")}</p>
										<img src={uploadIcon} alt="uplad icon" />
										<p className="mt-3">
											{t("UPLOAD.UPLOAD_BOX.TEXT2")}
											<br />
											{t("UPLOAD.UPLOAD_BOX.TEXT3")}
										</p>
									</>
								)}
							</p>
						)}
					</div>
				</div>
			</Row>
		</Container>
	);
};
export default IsLoadingHOC(FileDropZone, "Loading....");
