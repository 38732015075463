import React from "react";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CarouselCustom from "../../components/CarouselCustom";

const ThankYou = (props) => {
	window.scroll({
		top: 0,
		left: 0,
		behavior: "auto",
	});
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<div className="thankspage py-5">
				<div className="container">
					<Row className="justify-content-center">
						<Col className="col-sm-9">
							<h1 className="ff-StdHeavy uppercase mb-4">{t("Thank_you")}!</h1>
							<div className="mb-0 bg-dark-blue h-10"></div>
							<div className="bg-grey pb-5">
								<h2 className="pt-5 pb-4" dangerouslySetInnerHTML={{ __html: t("RECRIPT_MSG") }}></h2>
								<p className="text-center ff-StdBold mb-4" dangerouslySetInnerHTML={{ __html: t("thank_p_one") }}></p>
								<p className="text-center ff-StdHeavy my-5" dangerouslySetInnerHTML={{ __html: t("thank_p_two_a") }}></p>
								<p className="text-center ff-StdBold my-5" dangerouslySetInnerHTML={{ __html: t("thank_p_two_b") }}></p>
								<p className="text-center ff-StdHeavy my-5" dangerouslySetInnerHTML={{ __html: t("thank_p_two_c") }}></p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</>
	);
};

export default ThankYou;
