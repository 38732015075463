import React from "react";
import CarouselCustom from "../../components/CarouselCustom";
import Signup from "../../components/Signup";
const SignupScreen = (props) => {
	return (
		<>
			<CarouselCustom />
			<Signup />
		</>
	);
};

export default SignupScreen;
