import React from "react";
import FrequentlyAskedQuestions from "../../components/FrequentlyAskedQuestions";
import { useTranslation } from "react-i18next";
import CarouselCustom from "../../components/CarouselCustom";
const FaqScreen = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
		// eslint-disable-next-line
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<FrequentlyAskedQuestions />
		</>
	);
};

export default FaqScreen;
