import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import AppRoute from "./AppRoute";
import PublicLayout from "../components/layout/PublicLayout";
// import Register from "../screen/Signup/Register";
import Home from "../screen/Home";
import PrivateRoute from "./PrivateRoute";
import UploadReceipt from "../screen/ValidatePurchase/UploadReceipt";
import SubmissionConfirmation from "../screen/ValidatePurchase/SubmissionConfirmation";
import CartDelivery from "../screen/ValidatePurchase/CartDelivery";
import Upcoming from "../screen/CampaignStatus/Upcoming";
import Expired from "../screen/CampaignStatus/Expired";
import SupportScreen from "../screen/SupportScreen";
import FaqScreen from "../screen/FaqScreen";
import ThankYou from "../screen/ValidatePurchase/ThankYou";
import SignupScreen from "../screen/SignupScreen";
import ForgetPassword from "../screen/Auth/ForgetPassword";
import ResetPassword from "../screen/Auth/ResetPassword";
import ConfirmAccount from "../screen/Auth/ConfirmAccount";
import ParticipateProducts from "../screen/ParticipateProducts/index";
import FindMemberId from "../components/FindMemberId";
const routes = (
	<Switch>
		<AppRoute exact path="/" component={Home} layout={PublicLayout} />
		<AppRoute exact path="/forget-password" component={ForgetPassword} layout={PublicLayout} />
		<AppRoute exact path="/reset-password" component={ResetPassword} layout={PublicLayout} />
		<AppRoute exact path="/participating-products" component={ParticipateProducts} layout={PublicLayout} />
		<AppRoute exact path="/confirm-account" component={ConfirmAccount} layout={PublicLayout} />
		<AppRoute exact path="/register" component={SignupScreen} layout={PublicLayout} />
		<AppRoute exact path="/find-memberid" component={FindMemberId} layout={PublicLayout} />
		<PrivateRoute exact path="/upload-receipt" component={UploadReceipt} layout={PublicLayout} />
		<PrivateRoute exact path="/submission-confirmation" component={SubmissionConfirmation} layout={PublicLayout} />
		<PrivateRoute exact path="/cart-delivery" component={CartDelivery} layout={PublicLayout} />
		<AppRoute exact path="/upcoming" component={Upcoming} layout={PublicLayout} />
		<AppRoute exact path="/expired" component={Expired} layout={PublicLayout} />
		<AppRoute exact path="/support" component={SupportScreen} layout={PublicLayout} />
		<AppRoute exact path="/faq" component={FaqScreen} layout={PublicLayout} />
		<AppRoute exact path="/thankyou" component={ThankYou} layout={PublicLayout} />
	</Switch>
	// </>
);
export default routes;
