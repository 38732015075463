import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../data";

const VideoModal = (props) => {
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;

	const handleToggle = () => {
		props.handleClose();
	};
	return (
		<div>
			<Modal size="lg" centered show={props.isOpen} onHide={handleToggle} backdrop="static" keyboard={false} aria-label="vidoe-modal">
				<Modal.Header closeButton>
					<Modal.Title>{t("COMMON.QUESTION_VIDEO")}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<video className="w-100" controls autoplay>
						<source src={`${selected_lang == "en" ? commonImgData_en_exp.question_video : commonImgData_fr_exp.question_video}`} type="video/mp4" />
					</video>
				</Modal.Body>
			</Modal>
		</div>
	);
};

export default VideoModal;
