import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Container, Row, Col, Button, FormGroup, CloseButton } from "react-bootstrap";
import IsLoadingHOC from "../IsLoadingHOC";
import { toast } from "react-toastify";
import { uploadImageAction, validateUploadReceiptAction } from "../../redux/actions";
import PreviousReceiptList from "./PreviousReceiptList";
import FileDropZone from "../FileDropZone";
import { useSelector } from "react-redux";
import Captcha from "../Captcha";
import { generatePdfFromImages } from "../../hooks/ImagesToPDF";
import { useHistory } from "react-router-dom";

const UploadReceipt = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);

	const config = useSelector((state) => state.app.config);
	const [show, setShow] = useState(false);
	const [modalContentType, setModalContentType] = useState(0);
	const [formAddData, setFormAddData] = useState({ additionalInfo: { agree_to_program_scope: false, parseWithOcr: false } });
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	let [captchaEnable, setCaptchaEnable] = useState(false);
	let [selectedFile, setSelectedFile] = useState([]);
	const [receiptLoadCounter, setReceiptLoadCounter] = useState(1);
	let [enableUploading, setEnableUploading] = useState(true);
	const history = useHistory();
	const handleClose = () => {
		setShow(false);
		resetAllSelectionBtnClick();
	};
	const handleShow = () => {
		setModalContentType();
		setShow(true);
	};

	React.useEffect(() => {
		if (config.campaignState === "partialExpired") {
			history.push(`/`);

			// toast.error(`Sorry! You are not allowed to access this page. Promotion is ended.`);
			toast.error(t("COMMON.PARTIAL_EXPIRED_MSG"));
		}
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(false);
		}
		if (config.captchaConfig) {
			if (config.captchaConfig.uploadReceiptCaptcha) {
				setCaptchaEnable(true);
			}
		}
		//eslint-disable-next-line
	}, [config]);
	const checkUploadEnableOrNot = (uploadApiCall) => {
		props.setLoading(true);
		validateUploadReceiptAction({})
			.then((responseData) => {
				props.setLoading(false);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				if (responseData["httpCode"] >= 200 && responseData["httpCode"] <= 299) {
					setEnableUploading(responseData.data.valid);
					if (uploadApiCall) {
						uploadFileHandler(responseData.data.token);
					}
					return true;
				} else {
					toast.error(t("UPLOAD.ERROR.LIMIT_REACHED"));
				}
			})
			.catch((err) => {
				console.log("error", err);
				toast.error(t("UPLOAD.ERROR.LIMIT_REACHED"));
				props.setLoading(false);
			});
	};
	const uploadImage = () => {
		if (selectedFile.length === 0) {
			toast.error(t("UPLOAD.ERROR.SELECT_RECEIPT"));
			return false;
		}
		if (!captchaValue && captchaEnable) {
			toast.error(t("UPLOAD.ERROR.CAPTCHA_VAL"));
			return false;
		}
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const popupUploadBtnHandler = () => {
		if (config.receiptUploadPreValidation) {
			checkUploadEnableOrNot(true);
		} else {
			uploadFileHandler("");
		}
	};
	const uploadFileHandler = async (token) => {
		let fileData = selectedFile[0];
		if (selectedFile.length > 1) {
			fileData = await generatePdfFromImages(selectedFile);
		}
		const formData = new FormData();
		formData.append("type", "receipt");
		formData.append("image", fileData);
		if (token) {
			formData.append("token", token);
		}
		if (captchaEnable) {
			formData.append("captchaVal", captchaValue);
		}
		formData.append("submissionType", "warehouse");
		props.setLoading(true);
		uploadImageAction(formData)
			.then((responseData) => {
				props.setLoading(false);
				setResetCaptchaValue(resetCaptchaValue + 1);
				setReceiptLoadCounter(receiptLoadCounter + 1);
				setCaptchaValue("");
				if (responseData["httpCode"] === 200) {
					//toast.success(t("UPLOAD.SUBMIT.SUCCESS"));
					history.push(`/thankyou`);
					setSelectedFile([]);
					return true;
				} else if (responseData["httpCode"] === 409) {
					toast.error(t("UPLOAD.ERROR.DUPLICATE_RECEIPT"));
					setSelectedFile([]);
					return true;
				} else {
					toast.error(t("COMMON.SOMTHING_WRONG"));
					return false;
				}
			})
			.catch((err) => {
				setResetCaptchaValue(resetCaptchaValue + 1);
				console.log("error", err);
				props.setLoading(false);
				if (typeof err === "object") {
					if (err["response"]) {
						if (err["response"].httpCode === 409) {
							toast.error(t("UPLOAD.ERROR.DUPLICATE_RECEIPT"));
							setSelectedFile([]);
							return false;
						} else {
							toast.error(t("COMMON.SOMTHING_WRONG"));
							return false;
						}
					} else {
						toast.error(t("COMMON.SOMTHING_WRONG"));
						return false;
					}
				} else {
					toast.error(t("COMMON.SOMTHING_WRONG"));
					return false;
				}
			});
	};
	const resetAllSelectionBtnClick = () => {
		setShow(false);
		setSelectedFile([]);
		setResetCaptchaValue(resetCaptchaValue + 1);
		setCaptchaValue(false);
	};
	return (
		<Container className="justify-content-sm-center px-4">
			<div className="validate-purchase-div">
				<Col>
					<Row className="my-3">
						<div className="col-12">
							<h3 className="heading text-center dark-blue uppercase ff-StdHeavy">{t("UPLOAD.HEADING")}</h3>
						</div>
					</Row>
					<Row>
						<FileDropZone enableUploading={enableUploading} parentCallBack={setSelectedFile} selectedFile={selectedFile} />
					</Row>

					{captchaEnable ? (
						<Row md={1} className="mt-3">
							<Col>
								<Captcha reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
							</Col>
						</Row>
					) : (
						""
					)}

					{enableUploading ? (
						<Row className="justify-content-center">
							<Col className="text-center">
								<Button variant="primary" className="w-100" onClick={uploadImage}>
									{t("UPLOAD.SUBMIT.LABEL")}
								</Button>
							</Col>
						</Row>
					) : (
						""
					)}
					<Row className="mt-2">
						<div className="col-12">
							<ul className="upload-note pl-2" dangerouslySetInnerHTML={{ __html: t("UPLOAD.NOTE_LIST") }}></ul>
						</div>
					</Row>
				</Col>
			</div>
		</Container>
	);
};

export default IsLoadingHOC(UploadReceipt, "Loading....");
