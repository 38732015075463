import React from "react";
import { Card } from "react-bootstrap";
import { toast } from "react-toastify";
import IsLoadingHOC from "../../components/IsLoadingHOC";
import { connect } from "react-redux";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { forgotPasswordAction } from "../../redux/actions";
import { FormControl, Input, InputLabel } from "@material-ui/core";
import Required from "../../components/Required";
import { useTranslation } from "react-i18next";
import CarouselCustom from "../../components/CarouselCustom";
const ForgetPassword = (props) => {
	const history = useHistory();
	const { t, i18n } = useTranslation();
	const handleChange = (e, setValue) => {
		const { name, value } = e.target;
		setValue({ [name]: value });
	};
	return (
		<>
			<CarouselCustom />
			<div id="enternow" className="register-page signup-form">
				<div className="p-0 col-sm-9">
					<h1 className="mt-3 styleColor uppercase">{t("forgot password")}</h1>
					<div>
						<p className="text-center">
							<Required />
							{t("REGISTER.sub_heading1")}
						</p>
					</div>
					<div className="mb-0 bg-blue px-1 h-20"></div>
					<div className="px-2 py-3 pb-5 mt-0 mb-4 bg-grey">
						<div className="container-fluid">
							<Formik
								initialValues={{ email: "" }}
								validationSchema={Yup.object({
									email: Yup.string()
										.email(t("REGISTER.ERROR.EMAIL_INVALID_MSG"))
										.required(t("REGISTER.ERROR.EMAIL_REQ_MSG")),
								})}
								handleChange={handleChange}
								onSubmit={(values, { setSubmitting }) => {
									props.setLoading(true);
									forgotPasswordAction(values.email)
										.then((response) => {
											setSubmitting(false);
											props.setLoading(false);
											if (response.httpCode >= 200 && response.httpCode <= 299) {
												toast.success(t("REGISTER.ERROR.FORGOT_PASS_SUCCESS_EMAIL"));
												history.push("/");
											} else if (response.httpCode === 404) {
												toast.error(t("REGISTER.ERROR.FORGOT_PASS_404_EMAIL"));
											} else {
												toast.error(t("COMMON.SOMTHING_WRONG"));
											}
										})
										.catch((error) => {
											console.log(error);
											setSubmitting(false);
											props.setLoading(false);
											error.forEach((item) => {
												if (item.code === "NOT FOUND") {
													toast.error("Please provide your registered email and try again.");
												} else {
													toast.error(item.message);
												}
											});
										});
								}}>
								{(props) => (
									<form onSubmit={props.handleSubmit}>
										<div className="row justify-content-center">
											<div className="form-group mt-2 col-md-8">
												<FormControl fullWidth variant="outlined">
													<InputLabel fullWidth htmlFor="email" className="reg-input-lable">
														<Required />
														{t("REGISTER.FIELDS.EMAIL")}
													</InputLabel>
													<Input autoComplete="true" disableUnderline fullWidth name="email" id="email" onChange={(e) => handleChange(e, props.setValues)} aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-email" }} value={props.values.email} />
													<div className="input-error">
														<ErrorMessage name="email" />
													</div>{" "}
												</FormControl>
											</div>
										</div>

										<div className="row justify-content-center">
											<div className="px-md-5">
												<div className="mt-2 text-center w-100">
													<button data-test="reset-password-button" type="submit" className="btn btn-primary">
														{t("SUPPORT.SUBMIT.LABEL")}
													</button>
												</div>
											</div>
										</div>
									</form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default IsLoadingHOC(ForgetPassword, "Wait ....");
