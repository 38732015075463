import React from "react";
import { useTranslation } from "react-i18next";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../data";
import CarouselCustom from "./CarouselCustom";

const FindMemberId = () => {
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;

	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
		// eslint-disable-next-line
	}, [i18n.language]);
	return (
		<>
			<CarouselCustom />
			<div className="container-fluid">
				<div className="row p-sm-5">
					<div className="col-12 px-0 px-sm-5">
						<h1 className="ff-StdBook text-center d-sm-none py-4 black font-30px ff-StdHeavy">{t("COMMON.FIND_MEMBERSHIP_TITLE")}</h1>
						<div className="mb-0 bg-light-blue px-1 h-20"></div>
						<div className="mt-0 bg-grey ">
							<h1 className="ff-StdBook text-center d-none d-sm-block p-4 pt-5 black font-30px ff-StdHeavy">{t("COMMON.FIND_MEMBERSHIP_TITLE")}</h1>
							<div className="card-container">
								<div className="d-flex flex-column flex-sm-row justify-content-around">
									<div className="card-image card-img-1">{selected_lang === "en" ? <img className="d-block w-100 h-100 banner-img" src={commonImgData_en_exp.img7} alt={commonImgData_en_exp.title7} /> : <img className="d-block w-100 h-100 banner-img" src={commonImgData_fr_exp.img7} alt={commonImgData_fr_exp.title7} />}</div>
									<div className="card-image card-img-2">{selected_lang === "en" ? <img className="d-block w-100 h-100 banner-img" src={commonImgData_en_exp.img8} alt={commonImgData_en_exp.title8} /> : <img className="d-block w-100 h-100 banner-img" src={commonImgData_fr_exp.img8} alt={commonImgData_fr_exp.title8} />}</div>
									<div className="card-image card-img-3">{selected_lang === "en" ? <img className="d-block w-100 h-100 banner-img" src={commonImgData_en_exp.img9} alt={commonImgData_en_exp.title9} /> : <img className="d-block w-100 h-100 banner-img" src={commonImgData_fr_exp.img9} alt={commonImgData_fr_exp.title9} />}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default FindMemberId;
