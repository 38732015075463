import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form, Button, Alert, Section } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import IsLoadingHOC from "./IsLoadingHOC";
import { helpQueryAction } from "../redux/actions";
import { toast } from "react-toastify";
import Captcha from "./Captcha";
import { useSelector } from "react-redux";
import Required from "../components/Required";
import { FormControl, Input, InputLabel, MenuItem, Select, TextareaAutosize } from "@material-ui/core";
const Support = (props) => {
	const { t, i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const selected_lang = i18n.language;
	const [data, setData] = useState({
		memberId: "",
		state: "",
		name: "",
		email: "",
		message: "",
		captchaVal: "",
	});
	const config = useSelector((state) => state.app.config);
	let [captchaValue, setCaptchaValue] = useState(false);
	let [resetCaptchaValue, setResetCaptchaValue] = useState(0);
	const [error, setError] = useState({ memberId: "", state: "", name: "", email: "", message: "", captchaVal: "" });
	// eslint-disable-next-line
	const validationError = { memberId: "", state: "", message: "", name: /^[A-Za-z_ ]+$/, email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, captchaVal: "" };
	let [captchaEnable, setCaptchaEnable] = useState(false);
	useEffect(() => {
		let errorArray = JSON.parse(JSON.stringify(error));
		if (captchaValue) {
			errorArray["captchaVal"] = "";
			let reqd = JSON.parse(JSON.stringify(data));
			reqd.captchaVal = captchaValue;
			setData(reqd);
		}
		setError(errorArray);
		// eslint-disable-next-line
	}, [captchaValue]);
	useEffect(() => {
		if (config.captchaConfig) {
			if (config.captchaConfig.supportCaptcha) {
				setCaptchaEnable(true);
			}
		}
	}, [config]);
	const handleChange = (e) => {
		let key = e.target.name;
		let val = e.target.value;
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));
		if (key === "memberId") {
			if (isNaN(val) || val.indexOf(".") >= 0) {
				return false;
			}
		}
		if (val.match(validationError[key]) || val === "" || key === "email") {
			reqd[key] = val;
		}
		if (key === "name") {
			if (!val || val.trim().length < 1) {
				errd[key] = "SUPPORT.ERROR.NAME";
			} else {
				errd[key] = ``;
			}
		} else if (key === "email") {
			if (!val.match(validationError[key])) {
				errd[key] = "SUPPORT.ERROR.EMAIL";
			} else {
				errd[key] = ``;
			}
		} else if (key === "message") {
			if (!val || val.trim().length < 1) {
				errd[key] = "SUPPORT.ERROR.MESSAGE";
			} else {
				errd[key] = ``;
			}
		} else if (key === "memberId") {
			if (!val) {
				errd[key] = "SUPPORT.ERROR.MEMBERSHIPID_REQ_MSG";
			} else if (val.trim().length != 12) {
				errd[key] = "SUPPORT.ERROR.MEMBERSHIPID_INVALID_MSG";
			} else {
				errd[key] = ``;
			}
		} else if (key === "state") {
			if (!val || val.trim().length < 1) {
				errd[key] = "SUPPORT.ERROR.PR";
			} else {
				errd[key] = ``;
			}
		}
		setError(errd);
		setData(reqd);
	};
	const onSubmit = () => {
		let reqd = JSON.parse(JSON.stringify(data));
		let errd = JSON.parse(JSON.stringify(error));

		let isError = false;
		for (let key in reqd) {
			let val = reqd[key].trim();
			if (key === "name") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.NAME";
				} else {
					errd[key] = ``;
				}
			} else if (key === "email") {
				if (!val.match(validationError[key])) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.EMAIL";
				} else {
					errd[key] = ``;
				}
			} else if (key === "message") {
				if (!val || val.length < 1) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.MESSAGE";
				} else {
					errd[key] = ``;
				}
			} else if (key === "captchaVal" && captchaEnable) {
				if (!val || val.length < 2) {
					isError = true;
					errd[key] = "SUPPORT.ERROR.CAPTCHA_VAL";
				} else {
					errd[key] = ``;
				}
			} else if (key === "memberId") {
				if (!val) {
					errd[key] = "SUPPORT.ERROR.MEMBERSHIPID_REQ_MSG";
					isError = true;
				} else if (val.trim().length != 12) {
					errd[key] = "SUPPORT.ERROR.MEMBERSHIPID_INVALID_MSG";
					isError = true;
				} else {
					errd[key] = ``;
				}
			} else if (key === "state") {
				if (!val || val.trim().length < 1) {
					errd[key] = "SUPPORT.ERROR.PR";
					isError = true;
				} else {
					errd[key] = ``;
				}
			}
		}
		setError(errd);
		if (!isError) {
			props.setLoading(true);
			let requestData = { formType: "SUPPORT_ENQUIRY", email: reqd.email, name: `${reqd.name} `, message: reqd.message, additionalInfo: { memberId: reqd.memberId, state: reqd.state } };
			if (captchaEnable) {
				requestData["captchaVal"] = reqd.captchaVal;
			}
			helpQueryAction(requestData)
				.then((response) => {
					for (let key in reqd) {
						reqd[key] = "";
					}
					setResetCaptchaValue(resetCaptchaValue + 1);
					setData(reqd);
					props.setLoading(false);
					toast.success(t("SUPPORT.SUBMIT.SUCCESS"));
				})
				.catch((error) => {
					toast.error(t("COMMON.SOMTHING_WRONG"));
					props.setLoading(false);
				});
		}
	};
	return (
		<>
			<div id="enternow" className="register-page">
				<div className="p-0 col-sm-9">
					<form role="form" aria-label="Registration" className="signup-form">
						<h1 className="mt-4 styleColor">{t("SUPPORT.HEADING")}</h1>
						<div>
							<p className="text-center">
								<Required />
								{t("REGISTER.sub_heading1")}
							</p>
						</div>
						<div className="mb-0 bg-blue px-1 h-20"></div>
						<div className="px-2 py-3 pb-5 mt-0 bg-grey">
							<div className="container-fluid">
								<Row className="mt-5">
									<div className="form-group col-12 col-md-8">
										<FormControl fullWidth variant="outlined">
											<InputLabel htmlFor="memberId" className="reg-input-lable">
												<Required />
												{t("SUPPORT.MEMBERID")}
											</InputLabel>
											<Input autoComplete="true" value={data.memberId} disableUnderline fullwidth name="memberId" id="memberId" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-firstName" }} onChange={handleChange} />
											<span className="input-error">{t(error.memberId)}</span>
										</FormControl>
									</div>
									<div className="form-group pr col-12 col-md-4">
										<FormControl fullWidth variant="outlined">
											<InputLabel fullWidth id="PR" className="reg-input-lable" htmlFor="PR-Input">
												<Required />
												{t("SUPPORT.PR")}
											</InputLabel>
											<Select value={data.state} labelId="PR" inputProps={{ id: "PR-Input" }} name="state" onChange={handleChange} variant="standard" disableUnderline>
												<MenuItem value="AB">{t("REGISTER.FIELDS.ALBERTA")}</MenuItem>
												<MenuItem value="BC">{t("REGISTER.FIELDS.BRITISH_COLUMBIA")}</MenuItem>
												<MenuItem value="MB">{t("REGISTER.FIELDS.MANITOBA")}</MenuItem>
												<MenuItem value="NB">{t("REGISTER.FIELDS.NEW_BRUNSWICK")}</MenuItem>
												<MenuItem value="NL">{t("REGISTER.FIELDS.NEWFOUNDLAND_LABRADOR")}</MenuItem>
												<MenuItem value="NT">{t("REGISTER.FIELDS.NORTHWEST_TERRITORIES")}</MenuItem>
												<MenuItem value="NS">{t("REGISTER.FIELDS.NOVA_SCOTIA")}</MenuItem>
												<MenuItem value="NU">{t("REGISTER.FIELDS.NUNAVUT")}</MenuItem>
												<MenuItem value="ON">{t("REGISTER.FIELDS.ONTARIO")}</MenuItem>
												<MenuItem value="PE">{t("REGISTER.FIELDS.PRINCE_EDWARD_ISLAND")}</MenuItem>
												<MenuItem value="QC">{t("REGISTER.FIELDS.QUEBEC")}</MenuItem>
												<MenuItem value="SK">{t("REGISTER.FIELDS.SASKATCHEWAN")}</MenuItem>
												<MenuItem value="YT">{t("REGISTER.FIELDS.YUKON")}</MenuItem>
											</Select>
											<span className="input-error">{t(error.state)}</span>
										</FormControl>
									</div>
								</Row>
								<a className="blue mb-2 d-inline-block p-small ff-StdHeavy" target="new" href="/find-memberid">
									<Trans>{`${t("REGISTER.FIND_MEMBERSHIP_NUMBER")}`}</Trans>
								</a>
								<Row>
									{/*[START:FULLNAME]*/}
									<div className="form-group col-sm-6">
										<FormControl fullWidth variant="outlined">
											<InputLabel fullWidth htmlFor="name" className="reg-input-lable">
												<Required />
												{t("SUPPORT.NAME")}
											</InputLabel>
											<Input autoComplete="true" disableUnderline fullWidth name="name" id="name" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-name" }} value={data.name} onChange={handleChange} />
											<span className="input-error">{t(error.name)}</span>
										</FormControl>
									</div>

									{/*[END:FULLNAME]*/}

									{/*[START:EMAIL]*/}
									<div className="form-group col-sm-6">
										<FormControl fullWidth variant="outlined">
											<InputLabel fullWidth htmlFor="email" className="reg-input-lable">
												<Required />
												{t("SUPPORT.EMAIL")}
											</InputLabel>
											<Input value={data.email} autoComplete="true" disableUnderline fullWidth name="email" id="email" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-email" }} onChange={handleChange} />
											<span className="input-error">{t(error.email)}</span>
										</FormControl>
									</div>

									{/*[END:EMAIL]*/}
								</Row>

								<Row>
									<div className="form-group col-sm-12">
										<div className="w-100">
											<InputLabel fullWidth htmlFor="message" className="" style={{ color: "black"}}>
												<Required />
												{t("SUPPORT.MESSAGE")}
											</InputLabel>
											{/* <Input autoComplete="true" disableUnderline fullWidth name="email" id="email" aria-describedby="my-helper-text" className="reg-input-field" inputProps={{ "data-test": "register-email" }} onChange={handleChange} /> */}
											<TextareaAutosize className="w-100" name="message" id="message" minRows={10} value={data.message} onChange={handleChange} />
											<span className="input-error">{t(error.message)}</span>
										</div>
									</div>
								</Row>

								<Row className="justify-content-center">
									<div className="col-12 px-sm-5 mt-2 d-flex flex-column align-items-center">
										<div className="d-flex align-items-md-center px-2 px-sm-5 flex-column">
											{/*[START:GOOGLECAPTCHA]*/}
											<label htmlFor="g-recaptcha-response" className="col-sm-12 col-form-label d-none" aria-hidden="true">
												Google captcha
											</label>
											<Captcha align="left" reset={resetCaptchaValue} parentCallback={setCaptchaValue} />
											{/* <div className="input-error text-left">{error.captchaVal}</div> */}
											<span className="input-error">{t(error.captchaVal)}</span>
											{/*[END:GOOGLECAPTCHA]*/}
										</div>
									</div>
								</Row>
								<Row className="justify-content-center">
									<Col sm={6}>
										<div className="px-md-5">
											<div className="mt-3 text-center w-100">
												<button type="button" id="register-submit-btn" data-test="register-submit" onClick={onSubmit} className="btn btn-primary w-100">
													{t("SUPPORT.SUBMIT.LABEL")}
												</button>
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default IsLoadingHOC(Support, "Wait .....");
