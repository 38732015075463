import React from "react";
import ParticipateProducts from "../../components/ParticipateProducts";
import { useTranslation } from "react-i18next";
import CarouselCustom from "../../components/CarouselCustom";
const ParticipateInstructionsScreen = (props) => {
	const { t, i18n } = useTranslation();
	React.useEffect(() => {
		document.title = t("COMMON.TITLE");
		// eslint-disable-next-line
	}, [i18n.language]);
	return (
		<>
			<div className="mt-4">
				<CarouselCustom />
			</div>
			<ParticipateProducts />
		</>
	);
};

export default ParticipateInstructionsScreen;
