import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { commonImgData_en_exp, commonImgData_fr_exp } from "../data";
import IsLoadingHOC from "./IsLoadingHOC";
import axios from "axios";
const ParticipateProducts = (props) => {
	const config = useSelector((state) => state.app.config);
	const [productList, setProductList] = useState({ fr: [], en: [] });
	const [filterProductList, setFilterProductList] = useState([]);
	const { t, i18n } = useTranslation();
	const selected_lang = i18n.language;

    useEffect(async () => {
        try {
            //const response = await axios.get("/public/products.json");
            const apiResp = await fetch("/public/products.json");
            const response = await apiResp.json();
            if (response) {
                setProductList(response);
            } else {
                setProductList({ fr: [], en: [] });
            }
            props.setLoading(false);
        } catch (error) {
            props.setLoading(false);
            setProductList({ fr: [], en: [] });
            console.log(error);
        }
    }, []);

	const searchHandleChange = (e) => {
		let { value, name } = e.target;
		if (value.trim() === "") {
			setFilterProductList([]);
		} else {
			let ftlData = [];
			if (selected_lang.toUpperCase() === "FR") {
				ftlData = productList.fr.filter((v) => v.productName.toLowerCase().indexOf(value.toLowerCase()) >= 0);
			} else if (selected_lang.toUpperCase() === "EN") {
				ftlData = productList.en.filter((v) => v.productName.toLowerCase().indexOf(value.toLowerCase()) >= 0);
			}
			setFilterProductList(ftlData.slice(0, 5));
		}
	};
	// if (config.campaignState === "expired") {
	// 	return (
	// 		<Row className="justify-content-md-center section-content" id="participate-content">
	// 			<Col md={12} className="section-content">
	// 				<h1 className="text-center">{t("COMMON.PARTIAL_EXPIRED")}</h1>
	// 			</Col>
	// 		</Row>
	// 	);
	// } else {
	return (
		<div id="participate-content" className="participate-page">
			<Row className="justify-content-sm-center section-content pt-sm-4" aria-label="content">
				<div className="col-12 col-sm-9 ">
					<div className="bg-grey py-4 px-2 px-md-5">
						<label className="sr-only ff-StdMedium" htmlFor="inlineFormInputGroupUsername2">
							{t("COMMON.PARTICIPANTES_LABLE")}
						</label>
						<div className="input-group px-sm-2">
							<div className="input-group-prepend">
								<div className="input-group-text ff-StdHeavy"> {t("COMMON.PARTICIPANTES_LABLE")}</div>
							</div>
							<input type="text" onChange={searchHandleChange} className="form-control h-auto" id="inlineFormInputGroupUsername2" placeholder={t("COMMON.PARTICIPANTES_PLACEHOLDER")} />
							<div className="search-btn btn m-0 p-0">
								<span className="material-icons md-36">search</span>
							</div>
						</div>
					</div>
					{filterProductList.length > 0 && (
						<div className="search-result">
							<table className="table table-bordered table-striped">
								<thead className="bg-grey">
									<tr>
										<th scope="col" className="ff-StdHeavy">
											{t("COMMON.ITEM_DESC")}
										</th>
										{/* <th scope="col" className="ff-StdHeavy">
												{t("COMMON.ITEM_UPC")}
											</th> */}
									</tr>
								</thead>
								<tbody>
									{filterProductList.map((val, index) => {
										return (
											<tr>
												<td>{val.productName}</td>
												{/* <td>{val.UPC}</td> */}
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					)}
				</div>
				<div className="mb-3 mt-5 col-12 col-sm-9">
					<h1 className="text-center styleColor h1-small" dangerouslySetInnerHTML={{ __html: t("COMMON.PB2") }}></h1>
				</div>
				<div className="col-12 col-sm-9">
					{(function() {
						let productsImg;
						if (selected_lang == "en") {
							productsImg = commonImgData_en_exp.products;
						} else {
							productsImg = commonImgData_fr_exp.products;
						}
						return (
							productsImg.length > 0 &&
							productsImg.map((val, index) => {
								return (
									<div className="product-container mb-2" key={index}>
										<h2 className="ctg-heading ff-StdMedium my-4">{val.category}</h2>
										<div className="products-items">
											{val.product_logo.map((img) => (
												<div className="logo-item">
													<img src={img.url} alt={img.title} />
													{img.description && <div className={`p-small ${img.class ? img.class : ""} text-center`}>{img.description}</div>}
												</div>
											))}
										</div>
									</div>
								);
							})
						);
					})()}
				</div>
				<div className="col-12 col-sm-9 mt-4 d-sm-flex justify-content-sm-center PARTICIPANTES_CONTENT">
					<p className="w-75 p-small text-sm-center">{t("COMMON.PARTICIPANTES_CONTENT")}</p>
				</div>
			</Row>
		</div>
	);
	// }
};

export default IsLoadingHOC(ParticipateProducts, "Loading....");
