import React, { useState } from "react";
import { Container, Row, Col, Accordion, Card, Button, Nav } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
// import Support from "./Support";
import { useSelector } from "react-redux";
import VideoModal from "./VideoModal";
const FrequentlyAskedQuestions = () => {
	const [data, setData] = useState([
		{
			id: 0,
			clicked: false,
			name: "FAQ.Q1",
			content: "FAQ.A1",
		},
		{
			id: 1,
			clicked: false,
			name: "FAQ.Q2",
			content: "FAQ.A2",
		},
		{
			id: 2,
			clicked: false,
			name: "FAQ.Q3",
			content: "FAQ.A3",
		},
		{
			id: 3,
			clicked: false,
			name: "FAQ.Q4",
			content: "FAQ.A4",
		},
		{
			id: 4,
			clicked: false,
			name: "FAQ.Q5",
			content: "FAQ.A5",
		},
		{
			id: 5,
			clicked: false,
			name: "FAQ.Q6",
			content: "FAQ.A6",
		},
		{
			id: 6,
			clicked: false,
			name: "FAQ.Q7",
			content: "FAQ.A7",
		},
		{
			id: 7,
			clicked: false,
			name: "FAQ.Q8",
			content: "FAQ.A8",
		},
		{
			id: 8,
			clicked: false,
			name: "FAQ.Q9",
			content: "FAQ.A9",
		},
		{
			id: 9,
			clicked: false,
			name: "FAQ.Q10",
			content: "FAQ.A10",
		},
		{
			id: 10,
			clicked: false,
			name: "FAQ.Q11",
			content: "FAQ.A11",
		},
		{
			id: 11,
			clicked: false,
			name: "FAQ.Q12",
			content: "FAQ.A12",
		},
	]);
	const { t, i18n } = useTranslation();
	const [faqStatus, setFaqStatus] = useState(false);
	const [videoModal, setVideoModal] = useState(false);
	const toggleHandler = (i) => {
		let tempData = JSON.parse(JSON.stringify(data));
		tempData[i].clicked = tempData[i].clicked ? false : true;
		setData(tempData);
	};
	const bulkActionHandler = () => {
		let status = !faqStatus;
		setFaqStatus(status);
		let tempData = data.map((v) => ({ ...v, clicked: status }));
		setData(tempData);
	};
	const handleToggle = (e) => {
		const { id } = e.target;
		if (id === "toggleModal") {
			setVideoModal(!videoModal);
		}
	};

	return (
		<>
			<div className="section-content" id="faq-content">
				<section aria-label="FAQ">
					<Row className="justify-content-sm-center section-content">
						<Col sm={9}>
							{/* <h1 className="section-heading text-center mb-5">FREQUENTLY ASKED QUESTIONS</h1> */}
							<h1 className="my-3 styleColor text-center h1-small">FAQ</h1>
						</Col>
						<Col sm={9} className="faq-page">
							{data.map((v, i) => {
								return (
									<div key={i} className="my-2">
										<h2 className="styleColor ff-StdHeavy" dangerouslySetInnerHTML={{ __html: `Q: ${t(v.name)}` }}></h2>
										<p className="p-small" onClick={handleToggle} dangerouslySetInnerHTML={{ __html: `${t(v.content)}` }}></p>
									</div>
								);
							})}
						</Col>
					</Row>
				</section>
			</div>
			<VideoModal isOpen={videoModal} handleClose={() => handleToggle({ target: { id: "toggleModal" } })} />
			{/* <Support /> */}
		</>
	);
};

export default FrequentlyAskedQuestions;
