import React from "react";
import { useTranslation } from "react-i18next";
import { Carousel, Container } from "react-bootstrap";
import { useSelector } from "react-redux";
import { carouselData_en, carouselData_fr, carouselData_en_exp, carouselData_fr_exp } from "../data";
const CarouselCustom = () => {
	const { i18n } = useTranslation();
	const user = useSelector(({ user }) => user);
	const config = useSelector((state) => state.app.config);
	const selected_lang = i18n.language;
	return (
		<Container className="carouselCustom" fluid>
			<div className="row justify-content-center" id="home">
				{
					<Carousel className="col-12 m-0 p-0">
						<div>
							{selected_lang == "en" ? (
								<div>
									{config.campaignState !== "partialExpired"
										? carouselData_en.map((item, index) => {
												return (
													<div key={index}>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</div>
												);
										  })
										: carouselData_en_exp.map((item, index) => {
												return (
													<div key={index}>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</div>
												);
										  })}
								</div>
							) : (
								<div>
									{config.campaignState !== "partialExpired"
										? carouselData_fr.map((item, index) => {
												return (
													<div key={index}>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</div>
												);
										  })
										: carouselData_fr_exp.map((item, index) => {
												return (
													<div key={index}>
														{!user.accessToken && config.campaignState !== "partialExpired" ? (
															<a href="/register" className="w-100">
																<img className="d-block w-100 banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</a>
														) : (
															<>
																<img className="d-block w-100  banner-img desktop-hide" src={item.img} alt={item.title} />
																<img className="d-block w-100 mobile-hide banner-img" src={item.mobile_img} alt={item.title} />
															</>
														)}
													</div>
												);
										  })}
								</div>
							)}
						</div>
					</Carousel>
				}
			</div>
		</Container>
	);
};

export default CarouselCustom;
